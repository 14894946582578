export class items{
    
    
    public Id : string = "";
    public Name: string ="";
    public ItemDescription : string ="";
    public PhotoPath : string ="";
    public Category : string ="";
    public SubCategory : string ="";
    public Price : string ="";
    public Score : string ="";
    public Activation : string ="";

    
}